html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: #030A11;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline;
  background: 0 0;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

small {
  font-size: 14px;
}

strong {
  font-weight: 600;
}

input[type='button'],
button {
  outline: none;
}

input[type='button']::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
}

input[type='button']:focus,
button:focus,
button:active {
  outline: 0;
}

img {
  vertical-align: bottom
}

.btn, .btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus, a, a:active, a:focus, button, button:active, button:focus {
  outline: none;
  outline: 0;
  user-select: none
}

.simplebar-scrollbar {
  right: 0;
}

.simplebar-scrollbar:before {
  opacity: 0.2;
  border-radius: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 0;
  bottom: 0;
}

.vh-for-mobile {
  transition: height 0.15s;
  height: calc(var(--vh, 1vh) * 100) !important;
}

/* payment */
.injected-form__stripe-input-field {
  font-family: Barlow, sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  color: #000000;
}

.injected-form__promo-code-field {
  width: 50%;
  border: 0;
  font-family: Barlow, sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  color: #000000;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  padding: 8.5px;
}

@media (max-width: 400px) {
  .injected-form__promo-code-field {
    font-size: 10px;
  }
}

.auto-pay__divider {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider__text {
  margin: 0 8px;
  font-family: 'Barlow';
  color: black;
}

.auto-pay__divider::before,
.auto-pay__divider::after {
  content: '';
  width: 40%;
  height: 1px;
  background: black;
}

.pay-now-button {
  width: 100%;
  background: black;
  border: none;
  color: white;
  min-height: 56px;
}
