@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Barlow:400,700);
.subscribe-now-button__container {
  cursor: pointer;
  width: 243.1px;
  height: 63.6px;
  border-radius: 31.8px;
  border: none;
  box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
  background-image: -webkit-linear-gradient(13deg, #00bd98, #28d95a);
  background-image: linear-gradient(77deg, #00bd98, #28d95a); }
  .subscribe-now-button__container:hover {
    background-image: -webkit-linear-gradient(193deg, #00bd98, #28d95a);
    background-image: linear-gradient(257deg, #00bd98, #28d95a); }
  .subscribe-now-button__container.disabled {
    background: #cccccc none;
    cursor: default; }
  .subscribe-now-button__container.fail {
    background-image: -webkit-linear-gradient(193deg, #f10364, #fc172f);
    background-image: linear-gradient(257deg, #f10364, #fc172f); }
  @media (min-width: 350px) {
    .subscribe-now-button__container {
      width: 273.1px; } }

.subscribe-now-button__container svg {
  fill: red; }

.subscribe-now-button__text {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }
  @media (min-width: 350px) {
    .subscribe-now-button__text {
      font-size: 21px; } }

/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
.subscribe__block-new-version {
  width: 244px;
  height: 74px;
  border: 1px rgba(58, 59, 62, 0.5) solid;
  border-radius: 8px;
  box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 50% 50%;
  -webkit-transform: skew(-10deg);
          transform: skew(-10deg);
  font-family: "Barlow Condensed", sans-serif;
  text-align: left;
  -webkit-align-items: left;
          align-items: left;
  -webkit-flex-direction: column;
          flex-direction: column;
  background-color: #ffffff;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }
  @media (min-width: 350px) {
    .subscribe__block-new-version {
      width: 284px; } }
  .subscribe__block-new-version:focus {
    outline: none; }
  @media (min-width: 900px) {
    .subscribe__block-new-version:hover {
      background-color: rgba(0, 117, 249, 0.1); }
    .subscribe__block-new-version:focus {
      outline: 5px auto -webkit-focus-ring-color; } }
  .subscribe__block-new-version .subscribe__block-left-content {
    position: relative; }
    .subscribe__block-new-version .subscribe__block-left-content .subscribe__block-duration {
      font-size: 21px;
      padding: 16px 16px 0;
      font-weight: 700; }
    .subscribe__block-new-version .subscribe__block-left-content .subscribe__block-billing {
      font-family: "Barlow", sans-serif !important;
      font-size: 12px;
      padding-left: 16px;
      color: #95979a; }
    .subscribe__block-new-version .subscribe__block-left-content .subscribe__block-billing.selected {
      color: #ffffff; }
  .subscribe__block-new-version .subscribe__block-price {
    font-size: 28px;
    padding-top: 10px;
    padding-left: 25px; }
    @media (min-width: 350px) {
      .subscribe__block-new-version .subscribe__block-price {
        font-size: 34px; } }
    .subscribe__block-new-version .subscribe__block-price > h2 {
      font-weight: 700; }
  .subscribe__block-new-version > * {
    color: #111213;
    -webkit-transform: skew(10deg);
            transform: skew(10deg); }

.subscribe__block-new-version.selected {
  width: 246px;
  height: 76px;
  background-image: -webkit-linear-gradient(195deg, #0076f9, #1144ff);
  background-image: linear-gradient(255deg, #0076f9, #1144ff);
  border: none; }
  .subscribe__block-new-version.selected > * {
    color: #ffffff;
    -webkit-transform: skew(10deg);
            transform: skew(10deg); }
  @media (min-width: 350px) {
    .subscribe__block-new-version.selected {
      width: 286px; } }
  @media (min-width: 900px) {
    .subscribe__block-new-version.selected .subscribe__block-overlay {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      -webkit-transform: skew(-1deg);
              transform: skew(-1deg);
      border-radius: 8px;
      opacity: 0;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
    .subscribe__block-new-version.selected:hover .subscribe__block-overlay {
      opacity: 1; } }

@font-face {
  font-family: 'League Gothic';
  src: url(/static/media/leaguegothic-regular-webfont.1182d002.eot);
  src: url(/static/media/leaguegothic-regular-webfont.1182d002.eot?#iefix) format('embedded-opentype'),
  url(/static/media/leaguegothic-regular-webfont.29c587e6.woff) format('woff'),
  url(/static/media/leaguegothic-regular-webfont.19156af9.ttf) format('truetype'),
  url(/static/media/leaguegothic-regular-webfont.41e9ff90.svg#league_gothicregular) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: #030A11;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline;
  background: 0 0;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

small {
  font-size: 14px;
}

strong {
  font-weight: 600;
}

input[type='button'],
button {
  outline: none;
}

input[type='button']::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
}

input[type='button']:focus,
button:focus,
button:active {
  outline: 0;
}

img {
  vertical-align: bottom
}

.btn, .btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus, a, a:active, a:focus, button, button:active, button:focus {
  outline: none;
  outline: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.simplebar-scrollbar {
  right: 0;
}

.simplebar-scrollbar:before {
  opacity: 0.2;
  border-radius: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 0;
  bottom: 0;
}

.vh-for-mobile {
  -webkit-transition: height 0.15s;
  transition: height 0.15s;
  height: calc(1vh * 100) !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}

/* payment */
.injected-form__stripe-input-field {
  font-family: Barlow, sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  color: #000000;
}

.injected-form__promo-code-field {
  width: 50%;
  border: 0;
  font-family: Barlow, sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.41;
  letter-spacing: normal;
  color: #000000;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  padding: 8.5px;
}

@media (max-width: 400px) {
  .injected-form__promo-code-field {
    font-size: 10px;
  }
}

.auto-pay__divider {
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.divider__text {
  margin: 0 8px;
  font-family: 'Barlow';
  color: black;
}

.auto-pay__divider::before,
.auto-pay__divider::after {
  content: '';
  width: 40%;
  height: 1px;
  background: black;
}

.pay-now-button {
  width: 100%;
  background: black;
  border: none;
  color: white;
  min-height: 56px;
}

