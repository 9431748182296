@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:400,700");
@import url("https://fonts.googleapis.com/css?family=Barlow:400,700");
/*
  * functions
*/
/**
 * colors
 */
/* ========================================== */
/**
 * Fonts
 */
/**
 * line-height
 */
/**
 * Font Size
 */
/**
 * Font weights
 */
/**
 * Mixins
 */
/* ========================================== */
/**
 * breakpoints
 */
/**
 * Shared elements
 */
.subscribe__block-new-version {
  width: 244px;
  height: 74px;
  border: 1px rgba(58, 59, 62, 0.5) solid;
  border-radius: 8px;
  box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: 50% 50%;
  transform: skew(-10deg);
  font-family: "Barlow Condensed", sans-serif;
  text-align: left;
  align-items: left;
  flex-direction: column;
  background-color: #ffffff;
  position: relative;
  cursor: pointer;
  transition: all 0.2s; }
  @media (min-width: 350px) {
    .subscribe__block-new-version {
      width: 284px; } }
  .subscribe__block-new-version:focus {
    outline: none; }
  @media (min-width: 900px) {
    .subscribe__block-new-version:hover {
      background-color: rgba(0, 117, 249, 0.1); }
    .subscribe__block-new-version:focus {
      outline: 5px auto -webkit-focus-ring-color; } }
  .subscribe__block-new-version .subscribe__block-left-content {
    position: relative; }
    .subscribe__block-new-version .subscribe__block-left-content .subscribe__block-duration {
      font-size: 21px;
      padding: 16px 16px 0;
      font-weight: 700; }
    .subscribe__block-new-version .subscribe__block-left-content .subscribe__block-billing {
      font-family: "Barlow", sans-serif !important;
      font-size: 12px;
      padding-left: 16px;
      color: #95979a; }
    .subscribe__block-new-version .subscribe__block-left-content .subscribe__block-billing.selected {
      color: #ffffff; }
  .subscribe__block-new-version .subscribe__block-price {
    font-size: 28px;
    padding-top: 10px;
    padding-left: 25px; }
    @media (min-width: 350px) {
      .subscribe__block-new-version .subscribe__block-price {
        font-size: 34px; } }
    .subscribe__block-new-version .subscribe__block-price > h2 {
      font-weight: 700; }
  .subscribe__block-new-version > * {
    color: #111213;
    transform: skew(10deg); }

.subscribe__block-new-version.selected {
  width: 246px;
  height: 76px;
  background-image: linear-gradient(255deg, #0076f9, #1144ff);
  border: none; }
  .subscribe__block-new-version.selected > * {
    color: #ffffff;
    transform: skew(10deg); }
  @media (min-width: 350px) {
    .subscribe__block-new-version.selected {
      width: 286px; } }
  @media (min-width: 900px) {
    .subscribe__block-new-version.selected .subscribe__block-overlay {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transform: skew(-1deg);
      border-radius: 8px;
      opacity: 0;
      transition: all 0.2s; }
    .subscribe__block-new-version.selected:hover .subscribe__block-overlay {
      opacity: 1; } }
