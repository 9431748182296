.subscribe-now-button__container {
  cursor: pointer;
  width: 243.1px;
  height: 63.6px;
  border-radius: 31.8px;
  border: none;
  box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
  background-image: linear-gradient(77deg, #00bd98, #28d95a);

  &:hover {
    background-image: linear-gradient(257deg, #00bd98, #28d95a);
  }

  // Important that fail comes after hover as it shouldn't get the hover state styles.
  &.disabled {
    background: #cccccc none;
    cursor: default;
  }
  &.fail {
    background-image: linear-gradient(257deg, #f10364, #fc172f);
  }

  @media (min-width: 350px) {
    width: 273.1px;
  }
}

.subscribe-now-button__container svg {
  fill: red;
}

.subscribe-now-button__text {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  @media (min-width: 350px) {
    font-size: 21px;
  }
}
